.sideNavBottomLinkBanner {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-top: 16px;
}

.sideNavBottomLinkBanner__link {
  display: flex;

  &:hover {
    opacity: 0.8;
  }
}

.sideNavBottomLinkBanner__image {
  width: 100%;
  object-fit: cover;
}
