.header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  height: 152px;
  background-color: #fff;
}

.header__contentWrapper {
  width: 1200px;
}

.header__topRow {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  margin-bottom: 18px;
}

.header__logoWrapper {
  display: flex;
  gap: 20px;
  align-items: flex-end;

  img {
    display: block;
  }
}

.header__titleWrapper {
  width: 380px;
}

.header__iconSectionWrapper {
  display: flex;
  align-items: flex-end;
}

.header__forEmployersLink {
  margin-right: 20px;
  margin-bottom: 2px;
  color: #007fc3;

  &:hover {
    text-decoration: underline;
  }
}

.header_userNameWrapper {
  margin-right: 20px;
  margin-bottom: 2px;
  text-align: right;
}

.header__iconRegister {
  background: linear-gradient(315deg, #fd7931 0%, #ff5a00 100%);
  border-radius: 4px;

  > div {
    color: #fff;
  }
}
