.recommendCard__thumbnailImage {
  width: 100%;
  height: 143px;
  vertical-align: middle;
  object-fit: cover;
  border-radius: 4px 4px 0 0;

  &.recommendCard__thumbnailImage--wide {
    width: 241px;
    height: 169px;
  }
}
