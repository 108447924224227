.recommendStyle.slick-slider {
  &:has(.slick-dots) {
    padding-bottom: 42px;
  }
}

.recommendStyle .slick-list .slick-track {
  display: flex;
  padding-bottom: 5px;
}

.recommendStyle .slick-list {
  margin-bottom: -5px;
  margin-left: -9px;
}

.recommendStyle .slick-list .slick-track .slick-slide {
  display: flex;
  flex-direction: column;
  max-width: 213px;
  margin-left: 10px;
}

.recommendStyle .slick-dots {
  bottom: 0;
  display: flex;
  justify-content: center;

  ul {
    display: flex;

    li {
      width: 16px;
      height: 16px;
      margin: 0;

      &.slick-active {
        button::before {
          color: #007fc3;
          opacity: 1;
        }
      }

      button {
        padding: 0;

        &::before {
          top: 1px;
          width: 16px;
          height: 16px;
          font-size: 9px;
          color: #ebebeb;
          opacity: 1;
        }

        &:hover {
          &::before {
            color: #007fc3;
          }
        }
      }
    }
  }
}

.prevArrowStyle {
  margin-right: 20px;
}

.nextArrowStyle {
  margin-left: 20px;
}
