.top__signupLoginArea {
  position: relative;
}

.top__signupLogin {
  position: absolute;
  right: 0;
  bottom: calc(-100% + 20px);
}

.top__main {
  display: flex;
  justify-content: space-between;
  margin-top: 24px;
}

.top__mainLeft {
  width: 880px;
}

.top__aside {
  width: 288px;
}

.top_premiumOfferWrapper {
  position: fixed;
  right: 50%;
  bottom: 4%;
  z-index: 9999;
  display: flex;
  justify-content: flex-end;
  width: 1200px;
  pointer-events: none;
  transform: translateX(50%);
}

.top_recommendWrapper {
  &:not(:first-of-type) {
    margin-top: 40px;
  }
}
