.searchMenuLogIn {
  display: flex;
  margin-top: 8px;
  overflow: hidden;
  border-radius: 4px;
  box-shadow: 0 2px 5px rgb(0 127 195 / 15%);
}

.searchMenuLogIn__list {
  cursor: pointer;
  background-color: #fff;

  &--matchJob {
    flex: 4;
  }

  &--previousSearchJob {
    flex: 1;
  }

  &--jobSearchTop {
    flex: 1;
  }

  &:not(:last-of-type) {
    border-right: 1px solid #e5e5e5;
  }
}
