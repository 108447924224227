.searchMenuLogInParts__anchorLink {
  display: flex;
  gap: 0 16px;
  align-items: center;
  height: 48px;
  padding: 0 15px 0 20px;

  &:hover {
    background-color: #f0f9ff;
  }
}

.searchMenuLogInParts__listTextArea {
  display: flex;
  flex: 1;
  align-items: center;

  &--withTag {
    & .searchMenuLogInParts__listText {
      width: 502px;
    }
  }

  &--withoutTag {
    & .searchMenuLogInParts__listText {
      width: 580px;
    }
  }
}

.searchMenuLogInParts__listTitle {
  flex-shrink: 0;
  margin-right: 8px;
}

.searchMenuLogInParts__listText {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
