.recommendSliderTitle {
  font-size: 20px;
  font-weight: bold;
  line-height: 1.5;
}

.recommendSliderTitle_jobCount {
  margin: 0 4px;
  color: #f00;
}

.recommendSliderUpdateDate {
  font-size: 14px;
  font-weight: bold;
  line-height: 21px;
}
