.sideNavMiddleLinkBanner {
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 24px;
}

.sideNavMiddleLinkBanner__link {
  display: flex;

  &:hover {
    opacity: 0.8;
  }
}

.sideNavMiddleLinkBanner__image {
  width: 100%;
  object-fit: cover;
}
