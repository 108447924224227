.popularServices__title {
  margin-top: 40px;
}

.popularServices__banners {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 16px 20px;
  margin-top: 16px;
}
