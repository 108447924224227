.serviceLinks {
  display: flex;
  flex-direction: column;
  gap: 14px 0;
  padding-top: 24px;
  margin-top: 8px;
  border-top: 2px solid #e9f0f5;
}

.serviceLinks_wrapper {
  display: flex;
  align-items: center;
}

.serviceLinks_title {
  width: 110px;
  font-size: 12px;
  font-weight: bold;
  line-height: 18px;
}

.serviceLinks_listWrapper {
  display: flex;
  flex: 1;
  flex-wrap: wrap;
}

.serviceLinks_list {
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 8px 0;
  padding-left: 24px;
  border-left: 1px #ccc solid;
}

.serviceLinks_listItem {
  font-size: 12px;
  font-feature-settings: 'palt';
  line-height: 18px;

  & a:hover {
    color: #808080;
  }

  &:not(:last-child)::after {
    margin: 0 12px 0 10px;
    color: #ccc;
    content: '|';
  }
}

.serviceLinks_serviceList {
  display: flex;
  align-items: center;
}

.serviceLinks_serviceListTitle {
  width: 140px;
  font-size: 12px;
  font-weight: bold;
  line-height: 18px;
}

.serviceLinks_button {
  width: 150px;
}
