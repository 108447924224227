.workLocationSection {
  margin-top: 40px;
}

.workLocationSection__heading {
  padding: 8px 16px;
  margin-bottom: 10px;
  font-size: 14px;
  font-weight: 700;
  line-height: 14px;
  color: #fff;
  background-color: #007fc3;
  border-radius: 4px;
}

.workLocationSection__locationListRow {
  display: flex;
  column-gap: 45px;
  padding: 24px 32px;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 2px 5px 0 #007fc326;
}
