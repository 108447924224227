.urgentMessage {
  padding: 12px 16px;
  margin-top: 16px;
}

.urgentMessage__list {
  display: flex;
  flex-direction: column;
  gap: 4px 0;
}

.urgentMessage__anchorLink {
  font-size: 12px;
  line-height: 18px;
  color: #007fc3;

  &:hover {
    text-decoration: underline solid #007fc3;
  }
}
