.searchMenu__anchorLink {
  display: flex;
  gap: 0 16px;
  align-items: center;
  height: 70px;
  padding: 0 15px 0 20px;
  cursor: pointer;
  background-color: #fff;

  &:hover {
    background-color: #f0f9ff;
  }
}

.searchMenu__listTextArea {
  &--withTag {
    width: 296px;
  }

  &--withoutTag {
    width: 372px;
  }
}

.searchMenu__desiredConditionText {
  margin-top: 8px;
  overflow: hidden;
  line-height: 11px;
  text-overflow: ellipsis;
  white-space: nowrap;
}
