.favoriteBalloonNotice {
  padding: 12px 16px;
}

.favoriteBalloonNotice__heading {
  margin-bottom: 4px;
  font-size: 14px;
  font-weight: bold;
  line-height: 16px;
  color: #fff100;
}

.favoriteBalloonNotice__text {
  font-size: 12px;
  line-height: 16px;
  color: #fff;
}
