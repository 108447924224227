.headerResumePopover__wrapper {
  width: 300px;
  padding-bottom: 12px;
  margin-top: 12px;
}

.headerResumePopover_tag {
  margin-bottom: 6px;
}

.headerResumePopover_textWrapper {
  margin-bottom: 12px;
}
