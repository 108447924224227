.quickSearchLogInHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 16px;
  margin-bottom: 8px;
}

.quickSearchLogInHeader__jobCountArea {
  display: flex;
  align-items: center;
}

.quickSearchLogInHeader__jobCountText {
  margin-left: 4px;
  font-size: 12px;
  font-weight: 700;
  line-height: 12px;
}

.quickSearchLogInHeader__jobCount {
  margin-left: 12px;
  font-size: 14px;
  font-weight: 700;
  line-height: 14px;
  color: #f00;
}

.quickSearchLogInHeader__jobCountNumber {
  font-size: 14px;
  font-weight: 700;
  line-height: 14px;
}

.quickSearchLogInHeader__publicationDate {
  margin-left: 18px;
  font-size: 12px;
}

.quickSearchLogInHeader__UpdateDate {
  flex-shrink: 0;
  margin-left: 14px;
}

.quickSearchLogIn__list {
  display: flex;
  border-radius: 8px;
  box-shadow: 0 2px 5px 0 #007fc326;
}

.quickSearchLogInSelectBox__wrap {
  display: flex;
  align-items: center;
}

.quickSearchLogInInput__wrap {
  position: relative;
  display: flex;
  column-gap: 12px;
  align-items: center;
  justify-content: space-between;
  width: 400px;
  height: 48px;
  padding: 13.5px 12px;
  background: #fff;
  outline: none;

  &:hover {
    background: #f0f9ff;
  }
}

.quickSearchLogIn__input {
  width: 100%;
  font-size: 14px;
  background: #fff;
  background-color: transparent;
  border: none;
  border-radius: 4px;
  outline: none;

  &::placeholder {
    color: #aaa;
  }
}

.quickSearchLogIn__suggestList {
  width: 400px;
  margin-top: 2px;
}

.quickSearchLogInCheckbox__wrap {
  flex-shrink: 0;
}

.quickSearchLogIn__button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80px;
  height: 48px;
  padding: 10px;
  background: linear-gradient(135deg, #69af1c 0%, #7dba3c 100%);
  border-radius: 0 8px 8px 0;

  &:hover {
    background: #88c14b;
  }
}

.quickSearchLogIn__searchIcon {
  width: 32px;
  height: 32px;
  background: url('https://doda.jp/cmn_web/img/cm/icon/search_white.svg')
    no-repeat 50% 50%;
  background-size: 100%;
}
