.signupLoginArea__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 288px;
  padding: 24px;
}

.signupLoginArea__jobCountsText {
  margin-bottom: 8px;
  font-size: 11px;
  line-height: 15.4px;
}

.signupLoginArea__freeText {
  font-size: 12px;
}

.signupLoginArea__login {
  margin-top: 8px;
  margin-bottom: 16px;
  font-size: 11px;
  line-height: 15.4px;

  &--link {
    color: #007fc3;

    &:hover {
      text-decoration: underline;
    }
  }
}

.signupLoginArea__introButton {
  gap: 4px;
  height: 40px;
  padding: 0 16px;
  font-size: 14px;
  font-weight: 700;
  line-height: 1;

  &::before {
    position: relative;
    display: inline-block;
    width: 20px;
    height: 20px;
    content: '';
    background: url('https://doda.jp/cmn_web/img/cm/icon/beginner_blue.svg')
      no-repeat;
  }
}
