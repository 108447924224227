@mixin offerDetail-ellipsis {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.premiumOffer {
  width: 400px;
  margin-right: 4%;
  pointer-events: auto;
  background: #fff;
  border-radius: 4px;
}

.premiumOffer__ModalTitleWrapper {
  display: flex;
  gap: 0 12px;
  justify-content: space-between;
  padding: 12px 20px;
  background: #f44360;
  border-radius: 4px 4px 0 0;
}

.premiumOffer__ModalCloseButton {
  flex-shrink: 0;
  width: 24px;
  height: 24px;
  background: url('https://cms.doda.jp/cmn_web/img/cm/icon/close_white.svg')
    no-repeat 50% 50%;
  background-size: 100%;
}

.premiumOffer__ModalTitle {
  font-size: 14px;
  font-weight: bold;
  line-height: 21px;
  color: #fff;
}

.premiumOffer__wrapper {
  padding: 20px;
  border: 1px solid #e5e5e5;
  border-top: none;
  border-radius: 0 0 4px 4px;
}

.premiumOffer__offerDetail {
  display: flex;
  gap: 0 12px;
}

.premiumOffer__offerDetailWrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.premiumOffer__offerDetailTitle,
.premiumOffer__offerDetailOccupationName {
  @include offerDetail-ellipsis;
  line-height: 21px;
}

.premiumOffer__message {
  @include offerDetail-ellipsis;
  margin-top: 16px;
}

.premiumOffer__buttonWrapper {
  display: grid;
  gap: 12px 0;
  margin-top: 20px;
}

.premiumOffer__button {
  width: 240px;
  margin: 0 auto;
}
