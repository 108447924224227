.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 50000;
  display: none;
  width: 100vw;
  height: 100vh;
}

.modalOverlay--show {
  display: block;
}

.modalOverlay__contents {
  z-index: 55000;
  width: 100%;
  height: 100%;
}

.modalOverlay__background {
  z-index: 51000;
  width: 100%;
  height: 100%;
  background: rgb(0 16 26 / 80%);
}
