.footer {
  width: 1200px;
  margin: 56px auto 0;
}

.footer__PrivacyArea {
  margin-top: 24px;
}

.footer__externalSites {
  display: flex;
  justify-content: flex-end;
  margin: 16px 0 8px;
}
