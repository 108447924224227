.draggableSlider__container {
  display: flex;
  gap: 20px;
  overflow-x: auto;

  /* スクロールバー非表示（IE・Edge） */
  -ms-overflow-style: none;

  /* スクロールバー非表示（Firefox） */
  scrollbar-width: none;

  /* スクロールバー非表示（Chrome・Safari） */
  &::-webkit-scrollbar {
    display: none;
  }
}
