.specialistSiteSection__linkList {
  display: grid;
  grid-template-columns: repeat(5, 160px);
  gap: 16px 20px;
  margin-top: 40px;
}

.specialistSiteSection__link {
  &:hover {
    .specialistSiteSection__jobType,
    .specialistSiteSection__jobTypeExamples {
      text-decoration: underline;
    }
  }
}

.specialistSiteSection__jobType {
  margin-bottom: 4px;
  font-size: 12px;
  font-weight: 700;
  line-height: 18px;
}

.specialistSiteSection__jobTypeExamples {
  font-size: 11px;
  font-weight: 500;
  line-height: 15.4px;
  color: #aaa;
}
