.popover__wrapper {
  position: absolute;
  bottom: calc(-1 * (var(--height) + 15px));
  left: var(--position-left);
  z-index: 3000;
  width: 380px;
  padding: 12px 16px;
  background-color: #fff;
  filter: drop-shadow(0 2px 5px rgb(0 0 0 / 12%));
  border-radius: 4px;

  &::before {
    position: absolute;
    top: -12px;
    left: var(--arrow-position-left);
    content: '';
    border-right: 12px solid transparent;
    border-bottom: 12px solid #fff;
    border-left: 12px solid transparent;
  }
}

.popover__childrenWrapper {
  display: flex;
  justify-content: center;
}

.popover__closeButton {
  position: absolute;
  top: 12px;
  right: 12px;
}
