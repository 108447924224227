.todoList__todoCardList {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 8px;
}

.todoList__slideWrapper {
  display: flex;
  padding: 0 48px;

  /* react-slick のスタイルを上書きする */
  &.slick-slider {
    padding-bottom: 0;
  }

  & .slick-track {
    display: flex;
  }

  & .slick-slide {
    width: 352px;

    &:not(:last-of-type) {
      margin-right: 8px;
    }
  }

  & .slick-list {
    padding-bottom: 8px;
    margin-bottom: -8px;
  }

  & .slick-arrow {
    visibility: hidden;
  }
}

.todoList__sliderButton {
  position: absolute;
  top: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  background-color: #fff;
  border: 1px solid #007fc3;
  border-radius: 50%;
  transform: translateY(-50%);

  &:not(&--disabled):hover {
    background-color: #f0f9ff;
  }

  &--left {
    left: 0;
  }

  &--right {
    right: 0;
  }

  &--disabled {
    cursor: default;
    background-color: #f5f5f5;
    border-color: #f5f5f5;
  }
}
