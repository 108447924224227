.count {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.count__text {
  line-height: 21px;
}

.count__text__middle {
  line-height: 22.4px;
}
