$border: 1px solid #e5e5e5;

.knowAndAskContents__sectionTitleArea {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 130px;
  padding: 0 32px;
  margin-top: 40px;
  background-color: #4ebdf1;
  border-radius: 4px 4px 0 0;
}

.knowAndAskContents__sectionTitle {
  display: flex;
  align-items: center;
  border-bottom: 4px solid #fff100;
}

.knowAndAskContents__sectionTitleImage {
  position: absolute;
  right: 40px;
  bottom: 0;
}

.knowAndAskContents__sectionList {
  padding: 32px 32px 24px;
  background-color: #fafafa;
  border-right: $border;
  border-left: $border;
}

.knowAndAskContents__list {
  display: flex;
  column-gap: 24px;
}

.knowAndAskContents__listItem {
  flex: 1;
  overflow: hidden;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 2px 5px rgb(0 0 0 / 12%);

  &:hover {
    background-color: #f0f9ff;
  }
}

.knowAndAskContents__listItemTextArea {
  padding: 16px;
}

.knowAndAskContents__listItemTextAreaTitle {
  font-size: 14px;
  font-weight: 700;
  line-height: 21px;
  color: #007fc3;
}

.knowAndAskContents__listItemTextAreaText {
  margin-top: 4px;
  font-size: 12px;
  line-height: 18px;
  color: #808080;
}
