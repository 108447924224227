.brandPanel__wrapperr {
  height: 323px;
  margin-top: 16px;
}

.brandPanel {
  position: absolute;
  left: 0;
  width: 100vw;
  height: 323px;
  overflow: hidden;
  background: #d9d9d9;
}

.brandPanel__anchor {
  display: flex;
  justify-content: center;
}

.brandPanel__img {
  object-fit: cover;
}
