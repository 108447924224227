.sideNavTopLinkBanner {
  display: flex;

  &:not(:first-child) {
    margin-top: 16px;
  }

  &:hover {
    opacity: 0.8;
  }
}

.sideNavTopLinkBanner__image {
  width: 100%;
  object-fit: cover;
}
