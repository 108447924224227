.relatedWebsites__title {
  margin-top: 40px;
}

.relatedWebsites__banners {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 16px 8px;
  margin-top: 16px;
}
