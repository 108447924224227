.passwordChangeNotificationModal_background {
  position: fixed;
  inset: 0;
  z-index: 99999;
  cursor: default;
  background: rgb(0 16 26 / 80%);
}

.passwordChangeNotificationModal {
  display: flex;
  flex-direction: column;
  gap: 24px;
  align-items: center;
  justify-content: center;
  padding: 32px 56px;
  border-radius: 4px 0 0;
}

.passwordChangeNotificationModal__text {
  font-weight: 700;
  line-height: 21px;
  text-align: center;
}
