.toDoListArea__container {
  position: relative;
  min-height: 96px;
  padding: 8px 16px 12px;
  margin-top: 24px;
  background-color: #ddf1ff;
  border-radius: 4px;
}

.toDoListArea__title {
  margin-bottom: 8px;
  font-size: 12px;
  font-weight: 700;
  line-height: 1.5;
}

.toDoListArea__noTodoText {
  position: absolute;
  top: 50%;
  left: 50%;
  font-size: 12px;
  font-weight: 700;
  color: #aaa;
  transform: translate(-50%, -50%);
}

.toDoListArea__loading {
  width: 30px;
  height: 30px;
  margin: 0 auto;
  background-image: url('/assets/icon/icon_loading.svg');
}
