.shareCodeDialogWhole {
  font-family: '游ゴシック', 'Yu Gothic', sans-serif;

  p {
    height: 56px;
  }
}

.shareCodeContainer {
  display: flex;
  flex-direction: column;
  gap: 24px;
  align-items: center;
  justify-content: center;
  padding: 24px 32px 32px;
}

.shareCodeDialogButton {
  width: 150px;
  height: 40px;
}

.codeArea {
  display: flex;
  align-items: center;
  align-self: stretch;
  width: 388px;
  height: 40px;
  padding: 10px 12px;
  font-size: 14px;
  font-weight: 500;
  white-space: nowrap;
}
