.locationList__locationList {
  display: flex;
  flex-direction: column;
  gap: 20px;
  color: #007fc3;
  border: 4px;
}

.locationList__location {
  display: flex;
  column-gap: 20px;
  align-items: flex-start;
}

.locationList__area {
  width: 70px;
  font-size: 14px;
  font-weight: 700;
  line-height: 14px;

  &:hover {
    text-decoration: underline;
  }
}

.locationList__prefectureList {
  display: flex;
  flex-wrap: wrap;
  gap: 10px 12px;
  align-items: center;
  width: 296px;
  margin-top: 2px;

  > li {
    display: flex;
    align-items: flex-start;
  }
}

.locationList__prefecture {
  font-size: 12px;
  font-weight: 500;
  line-height: 12px;

  > a:hover {
    text-decoration: underline;
  }
}
