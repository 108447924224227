.suggestedJobs__container {
  padding: 8px 16px 12px;
  margin-top: 4px;
  background-color: #ddf1ff;
  border-radius: 4px;
}

.suggestedJobs__containerTitle {
  font-size: 12px;
  line-height: 1.5;
}

.suggestedJobs__title {
  font-size: 12px;
  font-weight: 700;
  line-height: 12px;
}

.suggestedJobs__list {
  display: flex;
  margin-top: 8px;
  overflow: hidden;
  border-radius: 4px;
  box-shadow: 0 2px 5px 0 #007fc326;
}

.suggestedJobs__listItem {
  flex: 1;

  &:not(:last-of-type) {
    border-right: 1px solid #e5e5e5;
  }
}
