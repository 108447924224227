$border: 1px solid #e5e5e5;

.recommendForYou__container {
  padding: 0 32px 32px;
  background-color: #fafafa;
  border-right: $border;
  border-bottom: $border;
  border-left: $border;
  border-radius: 0 0 4px 4px;
}

.recommendForYou__list {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 16px 24px;
  margin-top: 24px;
}

.recommendForYou__listDetail {
  display: flex;
  overflow: hidden;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 2px 5px rgb(0 0 0 / 12%);

  &:hover {
    background-color: #f0f9ff;
  }
}

.recommendForYou__listDetailLink {
  display: flex;
  flex: 1;
  gap: 12px;
  align-items: center;
  padding: 16px 16px 16px 20px;
}

.recommendForYou__listDetailImage {
  width: 56px;
  height: 56px;
  object-fit: cover;
  border-radius: 4px;
}

.recommendForYou__listDetailText {
  flex: 1;
}
