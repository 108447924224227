.income-dialog__header__text {
  line-height: 21px;
}

.income-dialog__header__middle-text {
  line-height: 22.4px;
}

.income-dialog__content-item {
  display: flex;
  justify-content: space-between;
  width: 448px;
  padding: 15px;
  text-align: center;
  border-bottom: 1px solid rgb(229 229 229 / 100%);
}

.income-dialog__content-item--right {
  display: flex;
  gap: 18px;
  align-items: center;
  justify-content: space-between;
}
