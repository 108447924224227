.section__title {
  margin-top: 40px;
  margin-bottom: 16px;
}

.section__block {
  &:not(:last-of-type) {
    margin-bottom: 24px;
  }
}

.section_subtitle {
  margin-bottom: 8px;
}

.jobSearch-carousel__link {
  position: relative;
  display: flex;
  width: 140px;
  height: 100px;

  &::before {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    content: '';
    background-color: rgb(0 0 0 / 40%);
    border-radius: 4px;
  }

  &:hover {
    opacity: 0.8;
  }
}

.jobSearch-carousel__image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 4px;
}

.jobSearch-carousel__name {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 140px;
  height: 100px;
  font-weight: 700;
  line-height: 1.5;
  color: #fff;
  text-align: center;
}
