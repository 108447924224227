.quickSearchSelectLogIn__background {
  position: fixed;
  inset: 0;
  cursor: default;
}

.quickSearchSelectLogIn__wrap {
  position: relative;
  z-index: 1;
}

.quickSearchSelectLogIn__container {
  display: flex;
  align-items: center;
  width: 240px;
  height: 48px;
  padding: 13.5px 12px;
  background-color: #fff;
  border-right: 1px solid #e5e5e5;

  &--occupation {
    border-radius: 4px 0 0 4px;
  }

  &:hover {
    background: #f0f9ff;
  }
}

.quickSearchSelectLogIn__selectedText {
  height: 48px;
  margin-right: 8px;
  margin-left: 4px;
  overflow: hidden;
  font-size: 14px;
  font-weight: 500;
  line-height: 48px;
  text-align: left;
  word-break: break-all;

  &--notSelected {
    color: #007fc3;
  }

  &--selected {
    color: #222;
  }
}

.quickSearchSelectLogIn__icon {
  flex: 0 0 auto;
  width: 20px;
  height: 20px;

  &--occupation {
    background-image: url('https://doda.jp/cmn_web/img/cm/icon/work_blue.svg');
  }

  &--location {
    background-image: url('https://doda.jp/cmn_web/img/cm/icon/location_blue.svg');
  }

  &--income {
    background-image: url('https://doda.jp/cmn_web/img/cm/icon/income_blue.svg');
  }
}

.quickSearchSelectLogIn__arrowIcon {
  margin-left: auto;
}

.quickSearchSelectLogInDropdown {
  position: absolute;
  width: 240px;
  max-height: 232px;
  padding: 4px 0;
  margin-top: 2px;
  overflow: auto;
  background: #fff;
  border: 1px solid #e5e5e5;
  border-radius: 4px;

  &--occupation {
    width: fit-content;
    white-space: nowrap;
  }

  &::-webkit-scrollbar {
    width: 8px;
    background-color: #e5e5e5;
    border-radius: 0 3px 3px 0;
    scrollbar-width: unset;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #aaa;
    border-radius: 4px;
  }

  .quickSearchSelectLogInDropdown__button {
    width: 100%;
    padding: 5.5px 12px;
    font-size: 14px;
    text-align: left;
  }

  .quickSearchSelectLogInDropdown__button:hover {
    background: #fafafa;
  }
}
