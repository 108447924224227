.headerNotificationPopover__wrapper {
  flex: 1;
  margin-top: 36px;
}

.headerNotificationPopover__scrollableWrapper {
  max-height: 454px;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background-color: #e5e5e5;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #aaa;
    border-radius: 10px;
  }
}

.headerNotificationPopover__noNotificationMessage {
  width: 100%;
  padding: 12px 16px;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  color: #f00;
  border: 1px solid #f00;
  border-radius: 4px;
}
