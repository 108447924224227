.recommendCard {
  width: 208px;
  padding-bottom: 16px;

  > div:last-child {
    padding: 0 16px;
  }

  &.recommendCard--wide {
    width: 241px;
  }
}

.recommendCard__cardDetail {
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  justify-content: space-between;
  margin: 12px 16px;

  &.recommendCard--isJobDetails {
    height: 130px;
  }

  &.recommendCard--notJobDetails {
    height: 82px;
  }
}

.cardDetail__link {
  display: grid;
  row-gap: 4px;

  &:hover .cardDetail__companyName {
    text-decoration: underline;
  }
}
