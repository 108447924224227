$border-style: 1px solid #e5e5e5;

.listStyle {
  padding: 10px 16px;
  font-size: 12px;
  line-height: 18px;
  color: #007fc3;
  background-color: #fff;
}

.eventSeminar__title {
  margin-top: 24px;
}

.eventSeminar__list {
  margin-top: 8px;
  overflow: hidden;
  border-top: $border-style;
  border-right: $border-style;
  border-left: $border-style;
  border-radius: 4px 4px 0 0;
}

.eventSeminar__listItem {
  &:not(:last-of-type) {
    border-bottom: $border-style;
  }

  &--notEvent {
    @extend .listStyle;
  }
}

.eventSeminar__anchor {
  @extend .listStyle;
  display: block;

  &:hover {
    background-color: #f0f9ff;
  }
}
