.labeledIcon__wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  width: 72px;
  height: 56px;

  &--orange {
    background: linear-gradient(315deg, #fd7931 0%, #ff5a00 100%);
    border-radius: 4px;

    > div {
      color: #fff;
    }
  }

  &:hover {
    background-color: #f7f7f7;
  }
}

.labeledIcon__iconWrapper {
  margin-bottom: 2px;
}

.labeledIcon__label {
  margin-bottom: 2px;
  font-size: 11px;
  color: #222;
}

.labeledIcon__budgeCount {
  position: absolute;
  top: 0;
  right: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 18px;
  height: 16px;
  padding-inline: 4px;
  font-family: Verdana, sans-serif;
  font-size: 10px;
  font-weight: 700;
  line-height: 11px;
  color: #007fc3;
  background-color: #fff100;
  border-radius: 20px;
}
