.notificationRow__link {
  display: block;
  padding: 12px 10px 18px;
  font-weight: 500;

  &:hover {
    background-color: #f7f7f7;
  }

  &--read {
    background-color: #fafafa;

    // TODO: IOコンポーネントのTextにこの色が追加されたあと、IOに置き換える
    .notificationRow__content {
      color: #ccc;
    }
  }
}

.notificationRow__metaRow {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 6px;
}

.notificationRow__tag {
  height: 18px;
  padding-inline: 8px;
  font-size: 11px;
  color: #fff;
  background-color: #ff5a00;
  border-radius: 2px;

  &--orange {
    background-color: #f60;
  }

  &--pink {
    background-color: #f44360;
  }

  &--blue {
    background-color: #007fc3;
  }

  &--yellow {
    background-color: #fc0;
  }

  &--green {
    background-color: #5fab12;
  }

  &--white {
    box-sizing: border-box;
    color: #333;
    background-color: #fff;
    border: solid 1px #e5e5e5;
  }
}

.notificationRow__entryDate {
  font-size: 11px;
  font-weight: 500;
  color: #808080;
}
