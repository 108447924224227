.summaryBottom--visible {
  display: flex;
  align-items: center;
}

.summaryBottom--invisible {
  display: none;
}

.summaryBottom__text {
  margin-right: 6px;

  &:hover {
    text-decoration: underline;
  }
}
