.cardDetail__detailsItem:first-of-type {
  margin-bottom: 4px;
  margin-left: -1px;
}

.cardDetail__detailsItem {
  display: flex;
  align-items: center;
}

.detailsItem__text {
  margin-left: 2px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
