.moveToListLink__listLink {
  display: flex;
  column-gap: 4px;
  align-items: center;
  justify-content: flex-end;
  padding: 9.5px 12px;
  font-size: 14px;
  line-height: 21px;
  color: #007fc3;
  background-color: #f5f5f5;
  border: 1px solid #e5e5e5;
  border-radius: 0 0 4px 4px;

  &:hover {
    text-decoration: underline;
  }
}
