.searchMenu {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1px 1px;
  margin-top: 40px;
  overflow: hidden;
  background-color: #e5e5e5;
  border-radius: 4px;
  box-shadow: 0 2px 5px rgb(0 127 195 / 15%);
}
