.favorite {
  position: relative;
  display: inline-block;
}

.favoriteBalloonNoticeWrapper {
  position: absolute;
  top: -108px;
  left: 50%;
  pointer-events: none;
  transform: translateX(-50%);
}
