.jobTypesSectionListItem__jobTypeTitle {
  margin-bottom: 6px;
  font-size: 12px;
  font-weight: 400;
  line-height: 12px;
  color: #007fc3;
}

.jobTypesSectionListItem__jobName {
  font-weight: 700;
}

.jobTypesSectionListItem__jobCount {
  margin-left: 3px;
  font-weight: 400;
}

.jobTypesSectionListItem__jobTypeSubCategories {
  font-size: 11px;
  font-weight: 500;
  line-height: 16px;
  color: #808080;
}
