.keywordSuggest__background {
  position: fixed;
  inset: 0;
  cursor: default;
  border: 1px solid #e5e5e5;
}

.keywordSuggest__menu {
  position: absolute;
  z-index: 1;
  display: flex;
  flex-direction: column;
  max-height: 232px;
  padding: 4px 0;
  margin-top: 2px;
  overflow-y: auto;
  background: #fff;
  border: 1px solid #e5e5e5;
  border-radius: 4px;

  &::-webkit-scrollbar {
    width: 8px;
    background-color: #e5e5e5;
    border-radius: 0 3px 3px 0;
    scrollbar-width: unset;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #aaa;
    border-radius: 4px;
  }
}

.keywordSuggest__button {
  width: 100%;
  padding: 5.5px 12px;
  font-size: 14px;
  text-align: left;
}

.keywordSuggest__button:hover {
  background: #fafafa;
}

.SuggestAPI.seghl {
  font-weight: bold;
}
