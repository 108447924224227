.bottomButton {
  position: fixed;
  bottom: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 75px;
  background: rgb(0 16 26 / 80%);
}

.bottomButton__item {
  width: 360px;
  height: 48px;
  margin: 15px;
}

.bottomButton__item--error {
  display: flex;
  flex-direction: column;
  pointer-events: none;
  background: rgb(255 0 0 / 100%);
}
