.basicLayout__wrapper {
  width: 1200px;
  margin: 0 auto;
}

.basicLayout__header {
  background-color: #fff;
}

.basicLayout__header__inner {
  width: 1200px;
  margin: 0 auto;
}
