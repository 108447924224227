$border-style: 1px solid #e5e5e5;

.newArrivalsFocusedContents__title {
  margin-top: 24px;
}

.newArrivalsFocusedContents__banners {
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  margin-top: 8px;
}

.newArrivalsFocusedContents__bannerLink {
  display: flex;

  &:hover {
    opacity: 0.8;
  }
}

.newArrivalsFocusedContents__banner {
  width: 100%;
  object-fit: cover;
}

.newArrivalsFocusedContents__list {
  margin-top: 8px;
  overflow: hidden;
  border-top: $border-style;
  border-right: $border-style;
  border-left: $border-style;
  border-radius: 4px 4px 0 0;
}

.newArrivalsFocusedContents__listItem {
  &:not(:last-of-type) {
    border-bottom: $border-style;
  }
}

.newArrivalsFocusedContents__anchor {
  display: block;
  padding: 10px 16px;
  font-size: 12px;
  line-height: 18px;
  color: #007fc3;
  background-color: #fff;

  &:hover {
    background-color: #f0f9ff;
  }
}
