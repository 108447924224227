.navigation_service {
  width: 110px;
}

.navigation_specialty {
  width: 110px;
}

.navigation_area {
  width: 72px;
}

.navigation_event {
  width: 143px;
}

.navigation_relatedInformation {
  width: 464px;
}
