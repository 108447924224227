$border: 1px solid #e5e5e5;

.jobSuggestionService__sectionTitleArea {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 130px;
  padding-left: 32px;
  margin-top: 40px;
  overflow: hidden;
  background-color: #4ebdf1;
  border-radius: 4px 4px 0 0;
}

.jobSuggestionService__sectionTitle {
  border-bottom: 4px solid #fff100;
}

.jobSuggestionService__sectionList {
  padding: 32px;
  background-color: #fafafa;
  border-right: $border;
  border-bottom: $border;
  border-left: $border;
  border-radius: 0 0 4px 4px;
}

.JobSuggestionService__list {
  display: flex;
  column-gap: 32px;
  justify-content: space-between;
}

.JobSuggestionService__listDetail {
  flex: 1;
  padding-right: 16px;
  overflow: hidden;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 2px 5px rgb(0 0 0 / 12%);

  &:hover {
    background-color: #f0f9ff;
  }
}

.JobSuggestionService__listDetailLink {
  display: flex;
}

.JobSuggestionService__listDetailTitle {
  margin-bottom: 4px;
  line-height: 21px;
}

.JobSuggestionService__listDetailText {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 10px 12px 10px 16px;
}

.JobSuggestionService__linkText {
  display: flex;
  gap: 0 4px;
  justify-content: flex-end;
  margin-top: 10px;
  font-size: 14px;
  color: #007fc3;

  &:hover {
    text-decoration: underline solid #007fc3;
  }
}
