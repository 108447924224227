.footer_wrapper {
  width: 1200px;
  margin-top: 50px;
}

.shareContent {
  display: flex;
  justify-content: space-between;
  width: 74px;
}

.navigation_service {
  width: 110px;
}

.navigation_specialty {
  width: 110px;
}

.navigation_area {
  width: 72px;
}

.navigation_event {
  width: 143px;
}

.navigation_relatedInformation {
  width: 464px;
}

.externalSites {
  display: flex;
  justify-content: flex-end;
  margin: 16px 0 8px;
}
