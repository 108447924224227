.DiagnosisDocumentCreatingTool__title {
  margin-top: 40px;
}

.DiagnosisDocumentCreatingTool__banners {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 16px 20px;
  margin-top: 16px;
}
