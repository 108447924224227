.moveToListLinkNoBackground__listLink {
  display: flex;
  column-gap: 4px;
  align-items: center;
  font-size: 14px;
  line-height: 28px;
  color: #007fc3;

  &:hover {
    text-decoration: underline;
  }
}
