.textLink {
  display: block;
  font-size: 11px;
  line-height: 1.4;
  color: #007fc3;

  &:hover {
    text-decoration: underline solid #007fc3;
  }
}
