$border-style: 1px solid #e5e5e5;

.popularJobsFeature__title {
  margin-top: 24px;
}

.popularJobsFeature__list {
  margin-top: 8px;
  overflow: hidden;
  border-top: $border-style;
  border-right: $border-style;
  border-left: $border-style;
  border-radius: 4px 4px 0 0;
}

.popularJobsFeature__listItem {
  &:not(:last-of-type) {
    border-bottom: $border-style;
  }
}

.popularJobsFeature__anchor {
  display: flex;
  column-gap: 16px;
  padding: 12px 16px;
  background-color: #fff;

  &:hover {
    background-color: #f0f9ff;
  }
}

.popularJobsFeature__cardTitle {
  margin-top: 4px;
  font-size: 12px;
  line-height: 18px;
  color: #007fc3;
}

.popularJobsFeature__image {
  flex-shrink: 0;
  width: 56px;
  height: 56px;
  overflow: hidden;
  object-fit: cover;
  border-radius: 4px;
}
