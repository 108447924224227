.passwordChangeNotificationHeader {
  padding: 12px 16px;
  margin-top: 16px;
  font-size: 12px;
  line-height: 21px;
}

.passwordChangeNotificationHeader__anchor {
  color: #007fc3;

  &:hover {
    text-decoration: underline solid #007fc3;
  }
}
