.item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 16px;
}

.item__tag {
  flex-shrink: 0;
}
