.box {
  display: flex;
  flex-direction: column;
  padding: 24px;
}

.box__header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.box__button {
  gap: 10px;
  width: 88px;
  height: 40px;
  padding: 0 16;
  border-radius: 8px;
}

.box__content {
  display: flex;
  gap: 12px;
}

.box__tag {
  flex-shrink: 0;
}
