// 共通で利用するCSSプロパティ
body.scroll-lock {
  overflow: hidden;
  overscroll-behavior: none;
  touch-action: none;
  -webkit-overflow-scrolling: none;
}

// user-agentの設定を上書きしています。
// user-agentの初期状態では、希望条件変更モーダルのデザインに合わせてダイアログの設定ができません。
// そのため一部のプロパティの値を制御、デザインを適用しています。
.dialog {
  position: unset;
  max-width: fit-content;
  height: 100vh;
  max-height: fit-content;
  padding: 0;
  background-color: rgb(240 249 255 / 100%);
  border-color: rgb(240 249 255 / 100%);
  border-top: 4px solid rgb(0 127 195 / 100%);
  border-right: 0;
  border-bottom: 0;
  border-left: 0;
}

.dialog__header {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100vw;
  padding: 24px;
}

.dialog__header__right {
  position: absolute;
  top: 15px;
  right: 20px;
  display: flex;
  align-self: flex-end;
  justify-content: center;
}

.dialog__layout {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100vw;
}

.selection-dialog__layout {
  gap: 24px;
}

.selection-dialog__content-upper {
  display: flex;
  align-items: center;
  width: 448px;
}

.dialog__content {
  display: flex;
  flex-direction: column;
  width: 448px;
  margin-bottom: 100px;
  background-color: rgb(255 255 255 / 100%);
  border-width: 0;
  border-bottom: 1px solid rgb(229 229 229 / 100%);
  border-radius: 4px;
  box-shadow: 0 2px 5px 0 rgb(0 127 195 / 15%);
}

.selection-dialog__content {
  margin-bottom: 100px;
}

.selection-dialog__content__item {
  background: rgb(255 255 255 / 100%);
}

.selection-dialog__content__item--error {
  background: rgb(255 240 242 / 100%);
}

.dialog__box--top {
  gap: 8px;
  border-bottom: 1px solid rgb(229 229 229 / 100%);
  border-radius: 4px 4px 0 0;
}

.dialog__box--middle {
  gap: 8px;
  border-bottom: 1px solid rgb(229 229 229 / 100%);
  border-radius: 0;
}

.dialog__box--bottom {
  gap: 8px;
}

.dialog__bottom {
  position: fixed;
  bottom: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 75px;
  background-color: rgb(0 16 26 / 80%);
}

.dialog__bottom__item {
  width: 360px;
  height: 50px;
  margin: 15px;
}
