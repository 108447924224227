.pr {
  padding: 16px;
  margin-top: 16px;
}

.pr__list {
  display: flex;
  flex-direction: column;
  gap: 8px 0;
  margin-top: 8px;
}
