.applicationUplift {
  padding: 32px 56px;
}

.applicationUplift__wrapper {
  display: grid;
  gap: 24px 0;
}

.applicationUplift__title {
  text-align: center;
}

.applicationUplift__text {
  margin-top: 8px;
  font-feature-settings: 'palt';
  line-height: 18px;
  text-align: center;
}

.applicationUplift__balloon {
  display: flex;
  align-items: center;
  margin-bottom: 14px;
}

.applicationUplift__balloonText {
  display: flex;
  flex-shrink: 0;
  align-items: flex-end;
  margin-left: 6px;
}

.applicationUplift__jobDetail {
  width: 338px;
  padding: 16px;
}

.applicationUplift__jobDetailTitle {
  word-wrap: break-word;
}

.applicationUplift__jobDetailCompanyName {
  margin-top: 5px;
  word-wrap: break-word;
}

.applicationUplift__jobDetailTag {
  margin-top: 12px;
}

.applicationUplift__button {
  width: 304px;
  margin: 0 auto;
  font-feature-settings: 'palt';
}

.applicationUplift__overlay {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 99999;
  width: 100%;
  height: 100%;
  cursor: default;
  background-color: rgb(0 16 26 / 80%);
}
