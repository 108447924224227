.suggestedJobCard__container {
  display: flex;
  align-items: center;
  height: 56px;
  padding: 0 16px;
  background-color: #fff;

  &:hover {
    background-color: #f0f9ff;
  }
}

.suggestedJobCard__textArea {
  margin-right: auto;
}

.suggestedJobCard__titleArea {
  display: flex;
  column-gap: 8px;
  align-items: center;
}

.suggestedJobCard__title {
  font-size: 14px;
  line-height: 21px;
  color: #007fc3;
}

.suggestedJobCard__tagText {
  color: #007fc3;
}

.suggestedJobCard__icon {
  flex-shrink: 0;
  width: 20px;
  height: 20px;
  margin-right: 8px;
  background-repeat: no-repeat;
  background-size: contain;

  &--careerAdviser {
    background-image: url('https://src.doda.jp/cmn_web/img/cm/icon/user_blue.svg');
  }

  &--recruitmentProject {
    background-image: url('https://src.doda.jp/cmn_web/img/cm/icon/headphone_blue.svg');
  }

  &--companyRecruiter {
    background-image: url('https://src.doda.jp/cmn_web/img/cm/icon/business_blue.svg');
  }

  &--partnerAgent {
    background-image: url('https://src.doda.jp/cmn_web/img/cm/icon/handshake_blue.svg');
  }
}

.suggestedJobCard__stopRequestText {
  font-size: 12px;
  line-height: 18px;
  color: #f00;
}
