.toDoListCard__anchor {
  display: flex;
  column-gap: 8px;
  align-items: center;
  height: 56px;
  padding: 0 16px;

  &:hover {
    background-color: #f0f9ff;
    border-radius: 4px;
  }
}

.toDoListCard__todoCardTitleArea {
  margin-right: auto;
}

.toDoListCard__todoCardTitle {
  font-size: 14px;
  font-weight: 600;
  line-height: 21px;
  color: #007fc3;
}

.toDoListCard__todoCardDescription {
  font-size: 12px;
  font-weight: 300;
  line-height: 18px;
  color: #aaa;
}

.toDoListCard__icon {
  flex-shrink: 0;
  width: 28px;
  height: 28px;
  background-repeat: no-repeat;
  background-size: contain;

  &--counselingDate {
    background-image: url('https://doda.jp/cmn_web/img/cm/icon/calendar_fix_blue.svg');
  }

  &--interviewDateSetting {
    background-image: url('https://doda.jp/cmn_web/img/cm/icon/calendar_fix_blue.svg');
  }

  &--dateSetting {
    background-image: url('https://doda.jp/cmn_web/img/cm/icon/calendar_set_blue.svg');
  }

  &--message {
    background-image: url('https://doda.jp/cmn_web/img/cm/icon/email_blue.svg');
  }

  &--resumeFileUpload {
    background-image: url('https://doda.jp/cmn_web/img/cm/icon/resume_blue.svg');
  }

  &--counseling {
    background-image: url('https://doda.jp/cmn_web/img/cm/icon/headphone_blue.svg');
  }

  &--desiredCondition {
    background-image: url('https://doda.jp/cmn_web/img/cm/icon/preferredConditions_blue.svg');
  }
}
