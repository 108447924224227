.imageBanner__anchor {
  display: flex;
  width: 100%;
  height: 82px;
  overflow: hidden;
  border-radius: 4px;
  box-shadow: 0 2px 5px rgb(0 127 195 / 15%);

  &:hover {
    opacity: 0.8;
  }
}

.imageBanner__image {
  width: 100%;
  object-fit: cover;
}
